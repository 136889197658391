import { render, staticRenderFns } from "./WizarNavOnly.vue?vue&type=template&id=4580bcb7&scoped=true"
import script from "./WizarNavOnly.vue?vue&type=script&lang=js"
export * from "./WizarNavOnly.vue?vue&type=script&lang=js"
import style0 from "./WizarNavOnly.vue?vue&type=style&index=0&id=4580bcb7&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4580bcb7",
  null
  
)

export default component.exports